import styled, { css } from 'styled-components';
import { Size } from '.';
import { colors } from '../../../styles/themeVariables';
import { typeScale } from '../../../styles/newTypography';
import { spacing } from '../../../styles/newThemeVariables.styles';
import Link from 'gatsby-link';

export const sizesStyles = (size: Size) =>
  ({
    sm: css``,
    md: css`
      text-transform: uppercase;
      padding: ${spacing.space10} ${spacing.space16};
      text-align: center;
      border: 0.078125rem solid ${({ theme }) => theme.border.Border400};
      border-radius: 6.25rem;

      &:hover {
        border: 0.078125rem solid ${({ theme }) => theme.border.Border500};
      }

      &:active {
        border: 0.078125rem solid ${({ theme }) => theme.border.Border600};
      }

      &:focus {
        border: 0.078125rem solid ${({ theme }) => theme.border.BorderFocus};
      }

      &:disabled {
        border: 0.078125rem solid ${({ theme }) => theme.border.Border500};
        color: ${({ theme }) => theme.text400};
      }
    `,
    lg: css``,
  }[size]);

const commonStyles = css<{
  size: Size;
  selected?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  color: ${({ theme }) => theme.textPrimary};
  background-color: transparent;

  && {
    ${typeScale.desktop.mono.md};
    line-height: initial;
    ${({ size }) => sizesStyles(size)};
    ${({ selected }) =>
      selected &&
      css`
        background-color: ${colors.black};
        color: ${({ theme }) => theme.textPrimaryInverse};
        border: 0.078125rem solid ${colors.black};

        &:hover {
          background-color: ${colors.black};
          border: 0.078125rem solid ${colors.black};
        }

        &:active {
          background-color: ${colors.black};
          border: 0.078125rem solid ${colors.black};
        }

        &:focus {
          background-color: ${colors.black};
          border: 0.078125rem solid ${colors.black};
        }

        &:focus-visible {
          background-color: ${colors.blue};
          border: 0.078125rem solid ${colors.blue};
        }
      `}
  }
`;

export const StyledAnchor = styled.a<{
  size: Size;
  href?: string;
  target?: string;
  selected?: boolean;
}>`
  ${commonStyles}
`;

export const StyledLink = styled(Link)<{
  size: Size;
  href?: string;
  target?: string;
  selected?: boolean;
}>`
  ${commonStyles}
`;

export const StyledLinkButton = styled.button<{
  size: Size;
  selected?: boolean;
}>`
  ${commonStyles}
`;

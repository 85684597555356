import { DefaultTheme } from 'styled-components';
import { border, fill, spacing, greyScale } from './newThemeVariables.styles';

// TODO: update to match new design system
export const colors = {
  white: '#ffffff',
  black: '#161314', // new black
  blackGrey: '#211d1e',
  grey: '#A7A7A7', // new slate
  cloud: '#D0CFCF', // new cloud
  greyMedium: '#53585F', // new charcoal
  charcoal: '#53585F',
  greyFaded: '#5D636A',
  greyDark: '#6F6F6F',
  greyLight: '#F7F7F7',
  greySuperLight: '#e0e0e0',
  grey600: '#262525',
  slate: '#979797',
  red: '#FE0108',
  neonOrange: '#FE0009',
  blue: '#1573FC',
  pink: '#FFAEAE',
  turquoise: '#48EFC7',
  yellow: '#FAFF39',
  orange: '#FBB413',
  cream: '#FFFFD4',
  lightHover: '#F7F7F7',
  darkHover: '#232222',
  neonCyan: '#48F0C8',
  snow: '#F0F0F0',
};

export const breakpointWidths = {
  xxs: '375px',
  xs1: '376px',
  xs: '576px',
  sm: '767px',
  md: '1024px',

  lg: '1440px',
  lgend: '1700px',

  xl: '1920px',
  xxl: '2200px',
};

const baseRemSize = 16;

const defaultTheme = {
  ...greyScale,
  ...colors,
  ...border,
  ...fill,
  ...spacing,

  remFontSize: `${baseRemSize}px`,

  maxContainerWidthLg: '1376px',
  maxContainerWidthXl: '1904px',

  breakpoints: {
    ...breakpointWidths,
    tablet: breakpointWidths.sm,
    desktop: breakpointWidths.md,
    desktopLarge: breakpointWidths.lgend,
    animationBreakpoint: breakpointWidths.xs,
  },

  monoFont: 'Courier, monospace',

  navHeight: '5.8125rem',
  navHeightMd: '5.0625rem',
  navHeightLg: '6rem',
  navToggleBtnAdjust: '-0.30rem', // To align with elsewhen lineheight;

  siteOuterMargin: '2rem',
  siteOuterMarginLg: '3rem',

  contentExtraIndent: '2rem',
  contentExtraIndentMd: '3.75rem',
  contentExtraIndentLg: '6.5rem',

  remGutterWidth: 1,
  gridSize: 12,

  sectionPaddingSm: '5.5rem',
  sectionPaddingLg: '8.5rem',

  // Spacing
  spacing: {
    space4: '0.25rem',
    space10: '0.625rem',
    space12: '0.75rem',
    space16: '1rem',
    space18: '1.125rem',
    space24: '1.5rem',
    space72: '4.5rem',
    space120: '7.5rem',
  },

  border: {
    Border200: border.border200,
    Border400: border.border500,
    Border500: border.border400,
    Border600: border.border600,
    BorderFocus: border.focus,
    BorderUnderline: greyScale.grey200,
    BorderUnderlineHover: greyScale.grey500,
    BorderUnderlineActive: greyScale.grey400,
  },

  text: {
    text400: '#807D7D',
    textPrimary: colors.black,
  },

  text1: colors.black,
  text2: colors.greyMedium,
  text3: colors.slate,
  fg: colors.black,
  fgFaint: colors.greyDark, // grey text on a standard bg
  fgFaded: colors.greyMedium,
  bg: colors.white,
  bgFaded: colors.greyLight,
  bgHover: colors.lightHover,
  // TODO: Document the difference between these 2, or deprecate in favour of fgFaint
  greyFg: colors.grey,
  greyWhiteFg: colors.greyDark,
  charSlate: colors.greyMedium,
  ghostButton: colors.cloud,
  error: colors.neonOrange,

  divider: '1px solid rgba(151, 151, 151, 0.16)',
  dropShadow: '0 0 2rem 0 rgba(0, 0, 0, 0.12)',
  revealTransition: '500ms ease-in-out',
  lightDivider: '1px solid rgb(211, 211, 211)',
  newLightDivider: `1px solid ${colors.grey}`,

  // Design system 2.1 text colors for light theme
  textPrimary: greyScale.grey700,
  text200: greyScale.grey600,
  text300: greyScale.grey500,
  text400: greyScale.grey400,
  text500: greyScale.grey300,
  textPrimaryInverse: greyScale.grey100,
  textError: '#C24621',
  textLink: colors.blue,
  textHighlight: '#FF9696',

  // Design system 2.1 background colors for light theme
  bgPrimary: greyScale.grey000,
  bgSecondary: greyScale.grey100,
  bgInversePrimary: greyScale.grey700,
  bgTranslucentWhite: greyScale.white5,
  bgTranslucentBlack: greyScale.black5,
  bgTranslucent: greyScale.black5,
};

export const darkTheme = (theme: DefaultTheme): DefaultTheme => ({
  ...theme,
  text1: colors.white,
  text2: colors.grey,
  text3: colors.greyMedium,
  fg: colors.white,
  fgFaded: colors.greyMedium,
  fgFaint: colors.grey,
  bg: colors.black,
  bgHover: colors.darkHover,
  bgFaded: colors.greyDark,
  greyFg: colors.grey,
  greyWhiteFg: colors.white,
  blue: '#438ffe',
  charSlate: colors.grey,
  ghostButton: colors.white,
  error: colors.neonOrange,
  grey600: colors.grey600,
  lightDivider: '1px solid rgba(251, 255, 255, 0.5)',
  newLightDivider: `1px solid ${colors.greyMedium}`,

  // Design system 2.1 text colors for dark theme
  textPrimary: greyScale.grey100,
  text200: greyScale.grey200,
  text300: greyScale.grey300,
  text400: greyScale.grey400,
  text500: greyScale.grey500,
  textPrimaryInverse: greyScale.grey700,
  textError: '#C24621',
  textLink: colors.blue,
  textHighlight: '#FF9696',

  // Design system 2.1 background colors for dark theme
  bgPrimary: greyScale.grey700_100,
  bgSecondary: greyScale.grey700,
  bgInversePrimary: greyScale.grey000,
  bgTranslucentWhite: greyScale.white5,
  bgTranslucentBlack: greyScale.black5,
  bgTranslucent: greyScale.white5,

  border: {
    Border200: border.border200,
    Border400: border.border400,
    Border500: border.border500,
    Border600: border.border600,
    BorderFocus: border.focus,
    BorderUnderline: greyScale.grey500,
    BorderUnderlineHover: greyScale.grey300,
    BorderUnderlineActive: greyScale.grey400,
  },
});

export type ElsewhenTheme = typeof defaultTheme;
export type Breakpoints = keyof typeof defaultTheme.breakpoints;

export default defaultTheme;

import styled from 'styled-components';
import { typeScale } from '../../../styles/newTypography';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';

export const MainTitle = styled.h2`
  ${typeScale.desktop.body.md}
  grid-column: 1 / -1;
  padding-top: ${spacing.space48};
  padding-bottom: ${spacing.space48};
  margin: 0;

  @media ${breakpointUp.tablet} {
    padding-bottom: ${spacing.space112};
  }

  @media ${breakpointUp.desktop} {
    padding-top: ${spacing.space120};
    padding-bottom: ${spacing.space64};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.lg}
  }
`;

export const Upper = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${spacing.space16};
  row-gap: ${spacing.space48};

  @media ${breakpointUp.tablet} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${spacing.space24};
    row-gap: ${spacing.space112};
  }
`;

export const SectionTitle = styled.h3`
  ${typeScale.mobile.mono.md}
  color: ${({ theme }) => theme.text300};
  padding-bottom: ${spacing.space8};
  margin: 0;

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.md}
    padding-bottom: ${spacing.space16};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.mono.md}
  }
`;

export const SectionList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const SectionListItem = styled.li`
  ${typeScale.mobile.body.sm}
  white-space: pre-wrap;
  padding-bottom: ${spacing.space8};

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.sm}
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.md}
  }
`;

export const Nav = styled.nav`
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    grid-column: span 3;
  }

  @media ${breakpointUp.desktop} {
    grid-column: span 2;
  }

  &:first-of-type {
    grid-column: 1 / -1;

    ${SectionListItem}:first-of-type {
      padding-bottom: 0;
    }

    ${SectionListItem}:nth-of-type(2) {
      padding-bottom: ${spacing.space8};
    }

    @media ${breakpointUp.tablet} {
      ${SectionListItem}:nth-of-type(2) {
        padding-bottom: ${spacing.space24};
      }
    }

    @media ${breakpointUp.desktop} {
      grid-column: span 3;

      ${SectionTitle}:first-of-type {
        padding-bottom: ${spacing.space8};
      }
    }
  }

  &:last-of-type {
    grid-column: 1 / -1;

    ${SectionListItem} {
      display: inline-flex;
      padding-right: ${spacing.space16};
      ${typeScale.mobile.body.xs}

      @media ${breakpointUp.tablet} {
        ${typeScale.desktop.body.md}
      }
    }

    @media ${breakpointUp.tablet} {
      grid-column: span 3;
    }
  }
`;

export const Lower = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: ${spacing.space48};
  padding-bottom: ${spacing.space24};
  column-gap: ${spacing.space16};
  row-gap: ${spacing.space48};
  ${typeScale.mobile.body.sm}
  color: ${({ theme }) => theme.text300};
  align-items: end;

  @media ${breakpointUp.tablet} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${spacing.space24};
    row-gap: ${spacing.space16};
    padding-top: ${spacing.space112};
    padding-bottom: ${spacing.space48};
    ${typeScale.desktop.body.xs}
  }

  @media ${breakpointUp.desktop} {
    padding-top: ${spacing.space128};
    padding-bottom: ${spacing.space72};
    row-gap: ${spacing.space24};
    white-space: nowrap;
  }
`;

export const Legal = styled.div`
  grid-column: 1;
  grid-row: 2;
  column-gap: 1rem;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  row-gap: ${spacing.space8};

  @media ${breakpointUp.tablet} {
    grid-column: span 6;
  }

  @media ${breakpointUp.desktop} {
    grid-column: -4 / span 3;
    grid-row: 1;
    justify-content: end;
  }
`;

export const Cookies = styled.button`
  color: ${({ theme }) => theme.text300};
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

export const Copyright = styled.div`
  grid-column: 2;
  grid-row: 2;
  text-align: right;

  @media ${breakpointUp.tablet} {
    grid-column: -4 / span 3;
  }

  @media ${breakpointUp.desktop} {
    grid-column: 1 / span 2;
    grid-row: 1;
    text-align: left;
  }
`;

export const Categories = styled.nav`
  grid-column: 1 / -1;
  grid-row: 1;

  @media ${breakpointUp.desktop} {
    display: inline-flex;
    column-gap: ${spacing.space16};
    grid-column: 3 / span 7;
    justify-content: center;
  }
`;

export const CategoryTitle = styled.h3`
  ${typeScale.desktop.mono.md}
  color: ${({ theme }) => theme.text300};
  padding-bottom: ${spacing.space10};
  margin: 0;

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xs}
    letter-spacing: normal;
    color: ${({ theme }) => theme.textPrimary};
    padding-bottom: 0;
  }
`;

export const CategoryList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: wrap;
  row-gap: ${spacing.space8};
`;

export const CategoryListItem = styled.li`
  display: inline-flex;
  ${typeScale.mobile.body.xs}
  white-space: pre-wrap;
  padding-right: ${spacing.space16};
  color: ${({ theme }) => theme.textPrimary};

  @media ${breakpointUp.tablet} {
    ${typeScale.mobile.body.sm}
    ${typeScale.desktop.body.xs}
    color: ${({ theme }) => theme.text300};
  }
`;

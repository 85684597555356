import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { breakpointWidths } from '../../../styles/themeVariables';
import { LayoutProps } from '.';

export const Layout = styled.div<LayoutProps>`
  display: grid;
  grid-template-columns:
    [full-start] minmax(0, 1fr) [main-start] minmax(
      0,
      ${({ variant, theme }) =>
        variant === 'fullWidth'
          ? breakpointWidths.xxl
          : variant === 'mediumWidth'
          ? breakpointWidths.xl
          : variant === 'smallWidth'
          ? `calc(${breakpointWidths.lg} - ${theme.space72})`
          : `calc(${breakpointWidths.xxl} - ${theme.space72})`}
    )
    [main-end] minmax(0, 1fr) [full-end];
  grid-column-gap: ${({ theme, spacing }) =>
    spacing === 'none' ? 0 : theme.space16};
  margin-inline: auto;
  width: 100%;

  @media (${breakpointUp.tablet}) {
    grid-column-gap: ${({ theme }) => theme.space24};
  }

  @media (${breakpointUp.desktop}) {
    grid-column-gap: ${({ theme }) => theme.space36};
  }

  @media (${breakpointUp.desktopLarge}) {
    grid-column-gap: ${({ theme }) => theme.space36};
    max-width: ${breakpointWidths.xxl};
  }

  /* Ensures direct child items are placed into the middle column */
  & > * {
    grid-column: main;
  }
`;
